<template>
    <div
        v-if="isInNavPill"
        id="NavPillFavButton"
        class="nav-pill display-flex-center" 
        :class="{ selected: !!isFav, disabled: favDisabled }"
        @click="$emit('toggleFavThis', $event)"
    >
        <div class="btn-fav small nav-pill-margin"></div>
    </div>
    <div
        v-else
        :class="{ selected: !!isFav, disabled: favDisabled }"
        @click="$emit('toggleFavThis', $event)"
    ></div>
</template>

<script>
export default {
    props: {
        isInNavPill: {
            type: Boolean,
            default: false,
        },
        isFav: {
            type: Boolean,
            default: false,
        },
        favDisabled: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
