<template>
  <div :class="[isActive ? 'active' : 'inactive', isStatic ? 'cog-static-wrapper' : 'cog-wrapper']">
    <img v-show="!isActive" src="../../../static/images/icons/icon_cog.png" />
    <img v-show="isActive" src="../../../static/images/icons/icon_cog_active.png" />
  </div>
</template>

<script>

export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    isStatic: {
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style scoped>
.cog-wrapper {
  cursor: pointer;
  display: flex;
  transition: transform 0.1s;
}
.cog-static-wrapper {
  display: flex;
}
.active {
  transform: rotate(360deg);
}
.inactive {
  transform: rotate(-360deg);
}
</style>
