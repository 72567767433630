<template>
    <svg
        width="21"
        height="14"
        viewBox="0 0 21 14"
        class="match-detail__icon"
        xmlns="http://www.w3.org/2000/svg"
        @click="$emit('toMatchDetailPage')"
    >
        <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.5 2H2.5V12H18.5V2ZM2.5 0C1.39543 0 0.5 0.89543 0.5 2V12C0.5 13.1046 1.39543 14 2.5 14H18.5C19.6046 14 20.5 13.1046 20.5 12V2C20.5 0.895431 19.6046 0 18.5 0H2.5Z"
        />
        <path d="M9.5 2H11.5V12H9.5V2Z" />
        <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.5 3H2.5C4.15685 3 5.5 4.34315 5.5 6V8C5.5 9.65685 4.15685 11 2.5 11H0.5V3ZM2.5 5V9C3.05228 9 3.5 8.55228 3.5 8V6C3.5 5.44772 3.05228 5 2.5 5Z"
        fill="inherit"
        />
        <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.5 6C15.5 4.34315 16.8431 3 18.5 3H20.5V11H18.5C16.8431 11 15.5 9.65685 15.5 8V6ZM18.5 5C17.9477 5 17.5 5.44772 17.5 6V8C17.5 8.55228 17.9477 9 18.5 9V5Z"
        fill="inherit"
        />
        <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.5 5C9.39543 5 8.5 5.89543 8.5 7C8.5 8.10457 9.39543 9 10.5 9C11.6046 9 12.5 8.10457 12.5 7C12.5 5.89543 11.6046 5 10.5 5ZM6.5 7C6.5 4.79086 8.29086 3 10.5 3C12.7091 3 14.5 4.79086 14.5 7C14.5 9.20914 12.7091 11 10.5 11C8.29086 11 6.5 9.20914 6.5 7Z"
        fill="inherit"
        />
    </svg>
</template>

<script>
export default {

}
</script>

<style scoped>
.match-detail__icon {
  margin: 0.2rem 0.07rem;/*0.2rem 0.5rem 0.2rem 0*/
  fill: white;
}
</style>